import React, { useCallback, useState } from 'react';
import { StatsCardContainer } from './StatsCard.styled';

export default function StatsCard({
  number = 100,
  subheader = '',
  textDecorator = '',
  position = 0
}) {
  const [animatedAmount, setAnimatedAmount] = useState(0);
  const [fadeDone, setFadeDone] = useState(false);
  const animationDelta = Math.ceil(number / 180);

  const animateAmount = useCallback(
     max => {
      setTimeout(() => {
        setAnimatedAmount(prevState => {
          if (prevState + animationDelta < max) {
            return prevState + animationDelta;
          }

          setFadeDone(true);

          return max;
        });
      }, 1);
    },
    [animationDelta]
  );

  if (fadeDone) {
    animateAmount(number);
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        className="backdrop"
        style={{
          position: 'absolute',
          borderRadius: 16,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.05)',
          backdropFilter: 'blur(10px)',
          top: 0,
        }}
      />
      <StatsCardContainer
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: {
            duration: 0.8,
            delay: (position + 1) * 0.2
          },
        }}
        viewport={{ once: true }}
        onAnimationComplete={() => setFadeDone(() => true)}
      >
        {/* <div className="backdrop" /> */}
        <span className="title">{animatedAmount}
          {textDecorator}
        </span>
        <span>{subheader}</span>
      </StatsCardContainer>
    </div>
  );
}
