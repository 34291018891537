import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { StatsHolderContainer } from './StatsCard.styled';
import StatsCard from './StatsCard';
import CallToAction from '../../molecules/CallToAction/CallToAction';

export default function StatsHolder({
  component: {
    backgroundVideo,
    header,
    kicker,
    subheader,
    tiles,
    callToAction,
    } }) {
  return (
    <StatsHolderContainer>
      <div className="overlay" />
      <video id="background-video" autoPlay loop muted poster={backgroundVideo.video.thumbnailUrl}>
        <source src={backgroundVideo.url} type="video/mp4" />
      </video>
      <div className="titles-container">
        {kicker && <h3 className="kicker">{kicker}</h3>}
        {header && <h2 className="title">{header}</h2>}
        {subheader && <p className="subtitle">{subheader}</p>}
      </div>
      <div className="items-container">
        <AnimatePresence mode="sync">
          {tiles.map((item, key) => (
            <StatsCard
              key={item.id}
              number={item.number}
              textDecorator={item.textDecoration}
              subheader={item.description}
              position={key}
            />
          ))}
        </AnimatePresence>
      </div>
      {callToAction && (
        <CallToAction
          variant="outlineWhite"
          openInNewTab={callToAction.openInNewTab}
          value={callToAction.label}
          link={callToAction?.url}
          containerClassName="above"
        />
      )}
    </StatsHolderContainer>
  );
}
