import styled from 'styled-components';
import { motion } from 'framer-motion';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { font } from '../../styles/atoms/typography';
import { colors } from '../../styles/atoms/colors';

export const StatsCardContainer = styled(motion.div)`
  display: flex;
  width: 248px;
  height: 176px;
  padding: 38px 28px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  outline: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  position: relative;
  z-index: 4;

  .backdrop {
    position: absolute;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
    top: 0;
  }

  span.title {
    color: ${colors.base.white};
    ${font('display', 'xl', '700')}
    z-index: 99;
    width: 100%;

    ${atMinWidth.xl`
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: CircularXX;
        font-size: 90px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 99px */
        letter-spacing: -0.3px;
    `}
  }

  span {
    color: ${colors.base.white};
    ${font('display', 'xs', '700')}
    z-index: 99;
    font-size: 22.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 24.75px */
    letter-spacing: -0.27px;
    text-align: center;
    overflow-wrap: break-word;

    ${atMinWidth.lg`
      max-width: 90%;
    `}
  }

  ${atMinWidth.lg`
    width: 184px;
    height: 226px;
    padding-top: 60px;
    padding-right: 28px;
    padding-left: 28px;
    padding-bottom: 0px;
    gap: 16px;
    flex-shrink: 0;
    `}

  ${atMinWidth.xl`
    width: 268px;
    height: 266px;
    padding-top: 60px;
    padding-right: 28px;
    padding-left: 28px;
    padding-bottom: 0px;
    gap: 28px;
    `}
`;

export const StatsHolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  position: relative;
  gap: 64px;

  .titles-container {
    z-index: 98;
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: center;
  }

  .above {
    z-index: 99;
  }

  .overlay {
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    position: absolute;
  }

  #background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  .kicker {
    color: var(--Base-White, var(--Color-Gray-white, #fff));
    text-align: center;
    font-family: CircularXX;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
    letter-spacing: 2px;
    text-transform: uppercase;
    z-index: 99;
    margin-bottom: 0px;
  }

  .title {
    color: var(--Color-Gray-white, #fff);
    text-align: center;
    font-family: CircularXX;
    font-size: 43.95px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 48.345px */
    letter-spacing: -0.3px;
    max-width: 969px;
    z-index: 99;
    margin-bottom: 0px;

    ${atMinWidth.lg`
      width: 80%;
    `}
  }

  .subtitle {
    color: var(--Base-White, var(--Color-Gray-white, #fff));
    text-align: center;
    font-family: CircularXX;
    font-size: 22.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 33.75px */
    letter-spacing: -0.27px;
    z-index: 99;
    width: 100%;
    margin-bottom: 0px;

    ${atMinWidth.lg`
      width: 80%;
    `}

    ${atMinWidth.xl`
      width: 90%;
    `}
  }

  .items-container {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 99;

    ${atMinWidth.xl`
      gap: 30px;
    `}
  }
`;
